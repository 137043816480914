$main: #3c4962;
$caption: #666666;
$main-300: #5b6f95;
$main-100: #8a99b7;
$info: #3b82f6;
$danger: #ff6b6c;
$danger-light: #fe000166;
$success: #03a226;
$success-dark: #17772c;
$warning: #feb21f;
$warning-dark: #d48e00;
$sucess-100: #bbf8c9;
$success-300: #04c82f;
$light-charcoal: #f0f5fb;
$dark-charcoal: #303a4e;
$dark-gray: #464749;
$gray: #6c757d;
$light-gray: #dee2e6;
$light-light-gray: #f5f5f5;
$ligth-blue: #f7faff;
$dark-blue: #404e68;
$gray-step: #757575;
$bg-table: #f6f8fb;

.bg-main {
    background: $main;
}

.surface-main {
    &:hover {
        background: $main;
    }
}

.text-main {
    color: $main;
}

.text-success {
    color: $success;
}

.text-danger {
    color: $danger;
}

.bg-main-300 {
    background-color: $main-300;
}

.text-main-300 {
    color: $main-300;
}

.bg-info {
    background-color: $info;
}

.bg-danger {
    background-color: $danger;
}

.bg-danger-light {
    background-color: $danger-light;
}

.bg-success {
    background-color: $success;
}

.bg-success-dark {
    background-color: $success-dark;
}

.bg-gray {
    background-color: $gray;
}

.bg-warning {
    background-color: $warning;
}

.bg-warning-dark {
    background-color: $warning-dark;
}

.text-info {
    color: $info;
}

.bg-success-300 {
    background-color: $success-300;
}

.text-success-300 {
    color: $success-300;
}
