@import 'colors.scss';

$focused-control-color: #1b74c5;
$invalid-control-border-color: #f44336;
$active-checkbox: #3b82f6;
$radioBorderColor: #d7d8d9;
$radioCheckedColor: #007bff;
$radioSize: 20px;

.ag-form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .controls {
        flex-grow: 1;
        align-content: flex-start;
        input,
        .p-dropdown,
        .p-autocomplete {
            height: 41px !important;
            border-radius: 6px;
        }
        .p-dropdown {
            align-items: center;
        }
        label {
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
        }
        .ng-invalid:focus {
            border-color: $focused-control-color !important;
        }
        .ng-invalid.ng-dirty:not(:focus) {
            border-color: $invalid-control-border-color !important;

            p-autocomplete {
                border-color: $invalid-control-border-color !important;
                .p-inputtext,
                .p-button {
                    border-color: $invalid-control-border-color;
                }
            }
            .ng-select-container {
                border-color: $invalid-control-border-color !important;
            }
        }
        input.p-filled ~ label,
        input:focus ~ label,
        p-dropdown:focus ~ label,
        .p-inputwrapper-filled ~ label,
        .p-inputtextarea:focus ~ label,
        .p-inputwrapper-focus ~ label,
        p-inputmask.p-inputwrapper-filled ~ label,
        p-inputmask.p-inputwrapper-focus ~ label,
        p-dropdown.p-inputwrapper-filled ~ label,
        textarea.p-filled ~ label,
        textarea:focus ~ label,
        p-inputnumber.p-inputwrapper-filled ~ label,
        p-inputnumber.p-inputwrapper-focus ~ label,
        p-autocomplete.p-inputwrapper-filled ~ label,
        p-autocomplete.p-inputwrapper-focus ~ label {
            color: $info;
            font-size: 10px;
            font-weight: 600;
            line-height: 10px;
        }

        .p-autocomplete-dropdown:disabled {
            .p-button-icon {
                display: none;
            }
        }

        .p-autocomplete-dd .p-autocomplete-input,
        .p-autocomplete-dd .p-autocomplete-multiple-container {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding: 0;
        button {
            width: unset;
            height: 38px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 17px;
            padding: 10.5px 17.5px;
            border-radius: 6px;
        }
        button.button-info {
            background-color: $info;
        }
        button.button-outlined-info {
            border-color: $info;
            color: $gray;
        }
    }

    .p-checkbox .p-checkbox-box {
        border-radius: 6px;
        width: 22px;
        height: 22px;
    }
    .p-checkbox .p-checkbox-box.p-highlight {
        border-color: $active-checkbox;
        background: $active-checkbox;
    }
}

app-search-bar {
    position: relative;
}

.outline-gray {
    border-color: #6c757d !important;
    background-color: #fff !important;
    color: #6c757d !important;
    font-weight: 700 !important;
    height: 38px !important;
    border-radius: 6px;
}

.radio-wrapper {
    position: relative;
    display: inline-block;
    line-height: none;
    .hidden-radio {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .radio-label {
        width: $radioSize;
        height: $radioSize;
        cursor: pointer;
        border-radius: 3px;
        margin: 0;
        border: 2px solid $radioBorderColor;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        pointer-events: none;
        .radio-icon {
            opacity: 0;
            color: white;
            pointer-events: none;
        }
    }
    .hidden-radio:checked + .radio-label {
        border-color: #1b74c5;
        background: #1b74c5;
        .radio-icon {
            opacity: 1;
        }
    }
}

app-validation-error {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
}

.field,
.controls,
.control {
    position: relative;
}

p-calendar {
    .p-calendar {
        input.p-inputtext {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
