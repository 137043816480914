/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';

// AGI-LOG generic styles:

@import './styles/forms.scss';
@import './styles/containers.scss';
@import './styles/layouts.scss';
@import './styles/tables.scss';
@import './styles/table-page-layout.scss';
@import './styles/clear-filter.scss';
@import './styles/tooltip.scss';
@import './styles/details.scss';
@import './styles/titles.scss';
@import './styles/request-creation-page-layout.scss';
@import './styles/autocomplete.scss';
