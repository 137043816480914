$content-padding: 12px;
.ag-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    grid-template-columns: 100%;
    grid-template-areas: 'header' 'main';

    header {
        grid-area: header;
        h4 {
            margin: 1.5rem 0;
        }
    }

    main {
        grid-area: main;
        padding: $content-padding;
    }
}
