@import 'colors.scss';
.ag-table {
    .table-header {
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }
    .p-datatable,
    .p-treetable {
        height: 100%;
        background-color: white;
        tr {
            cursor: pointer;
        }
    }
    .p-sortable-column-icon.pi.pi-fw.pi-sort-alt {
        visibility: hidden;
    }
    td.ag-center-td-content {
        text-align: center !important;
    }
    .p-datatable-wrapper {
        flex-basis: auto !important;
        height: 0 !important;
    }
}

.ag-treetable {
    .p-treetable-wrapper,
    .p-treetable-scrollable-wrapper {
        flex-basis: auto !important;
        height: 0 !important;
        tbody {
            tr {
                &:nth-child(even) {
                    background-color: $light-light-gray;
                }
            }
            td {
                padding: 0.5rem;
                border: none;
            }
        }
    }
}
